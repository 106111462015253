import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../assets/logo/icon.png";
import axios from "axios";

interface ForgotPasswordFormData {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const [formData, setFormData] = useState<ForgotPasswordFormData>({
        email: "",
    });
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData({ email: value });
        setError("");
    };

    const validateForm = () => {
        if (formData.email.trim() === "") {
            setError("Email is required");
            return false;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            setError("Invalid email address");
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/forgot-password`,
                    formData
                );

                if (response.status === 200) {
                    setSuccess(true);
                    localStorage.setItem("reset_email", formData.email);
                    setTimeout(() => {
                        navigate("/reset-password");
                    }, 3000);
                }
            } catch (error: any) {
                setError(error.response?.data?.message || "An error occurred. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (success) {
        return (
            <div className="flex justify-center items-center">
                <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                    <div className="mb-6">
                        <div className="flex justify-center">
                            <img src={icon} alt="logo" className="h-12 md:h-16 mb-2" />
                        </div>
                        <p className="text-2xl mb-5 font-bold">Check Your Email</p>
                    </div>
                    <p className="mb-6">
                        We've sent a verification code to {formData.email}. Please check your email
                        and enter the code on the next page.
                    </p>
                    <p className="text-sm text-gray-600 mb-6">
                        Redirecting you to the password reset page...
                    </p>
                    <button
                        type="button"
                        className="btn btn-primary w-full text-white"
                        onClick={() => navigate("/reset-password")}
                    >
                        Continue to Reset Password
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex justify-center mt-[100px]">
            <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                <div className="mb-6">
                    <div className="flex justify-center">
                        <img src={icon} alt="logo" className="h-12 md:h-16 mb-2" />
                    </div>
                    <p className="text-2xl mb-5 font-bold">Reset Your Password</p>
                    <p className="text-gray-600">
                        Enter your email address and we'll send you a verification code to reset
                        your password.
                    </p>
                </div>

                <div className="mb-6">
                    <label htmlFor="email" className="flex pl-1 pb-1">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        required
                        className={`input input-bordered focus:outline-none w-full ${
                            error ? "border-red-500" : ""
                        }`}
                        onChange={handleInputChange}
                        value={formData.email}
                        onKeyPress={e => {
                            if (e.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                    />
                    {error && <p className="text-red-500 text-xs italic text-left mt-1">{error}</p>}
                </div>

                <button
                    type="button"
                    className="btn btn-primary w-full text-white"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className="loading loading-spinner loading-xs">Loading...</span>
                    ) : (
                        "Send Reset Code"
                    )}
                </button>

                <p className="mt-4">
                    Remember your password?{" "}
                    <a href="/login" className="text-blue-500 hover:underline">
                        Sign in
                    </a>
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;
