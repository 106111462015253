import React, { useCallback, useState } from "react";
import axios from "axios";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { BiSortDown, BiTime, BiSort } from "react-icons/bi";
import { Comment } from "./types";
import { CommentItem } from "./CommentItem";
import { AddComment } from "./AddComment";
import { Dropdown } from "../Dropdown";

interface CommentsSectionProps {
    comments: Comment[];
    onSeek: (time: number) => void;
    videoId: string;
    currentTime: number;
    isPlaying: boolean;
    setIsPlaying: (playing: boolean) => void;
    onCommentAdded: () => void;
    currentUserEmail: string;
    isLoading: boolean;
    readOnly: boolean;
    shareToken?: string;
}

type SortOption = "latest" | "timeline";

const CommentsSection: React.FC<CommentsSectionProps> = ({
    comments: initialComments,
    onSeek,
    videoId,
    currentTime,
    isPlaying,
    setIsPlaying,
    onCommentAdded,
    currentUserEmail,
    isLoading,
    readOnly = true,
    shareToken,
}) => {
    const [loadingReplies, setLoadingReplies] = useState<Record<string, boolean>>({});
    const [sortOption, setSortOption] = useState<SortOption>("timeline");
    const [comments, setComments] = useState<Comment[]>(initialComments);

    React.useEffect(() => {
        setComments(sortComments(initialComments, sortOption));
    }, [initialComments, sortOption]);

    const sortComments = (commentsToSort: Comment[], option: SortOption): Comment[] => {
        const sortedComments = [...commentsToSort];

        const topLevelComments = sortedComments.filter(comment => !comment.parent_comment);
        const replyComments = sortedComments.filter(comment => comment.parent_comment);

        const sortedTopLevel = [...topLevelComments].sort((a, b) => {
            switch (option) {
                case "latest":
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                case "timeline":
                    return a.start_time - b.start_time;
                default:
                    return 0;
            }
        });

        return [...sortedTopLevel, ...replyComments];
    };

    const getHeaders = () => {
        if (shareToken) {
            return { "X-Share-Token": shareToken };
        }
        const token = localStorage.getItem("token");
        return { Authorization: `Bearer ${token}` };
    };

    const fetchReplies = useCallback(
        async (commentId: string): Promise<Comment | null> => {
            try {
                setLoadingReplies(prev => ({ ...prev, [commentId]: true }));
                const response = await axios.get<Comment>(
                    `${process.env.REACT_APP_API}/api/quick-review/comments/${videoId}/${commentId}`,
                    { headers: getHeaders() }
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching replies:", error);
                return null;
            } finally {
                setLoadingReplies(prev => ({ ...prev, [commentId]: false }));
            }
        },
        [videoId, shareToken]
    );

    const sortingOptions = [
        {
            label: "Latest Comments",
            onClick: () => setSortOption("latest"),
        },
        {
            label: "Video Order",
            onClick: () => setSortOption("timeline"),
        },
    ];

    const commentCount = comments.filter(comment => !comment.parent_comment).length;

    return (
        <div className="h-full flex flex-col">
            <div className="bg-white rounded-t-lg shadow-sm border-b border-gray-200 px-4 py-2">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                        <h3 className="text-xl font-semibold">Comments</h3>
                        <span className="text-sm text-gray-500">({commentCount})</span>
                    </div>
                    <div
                        className="tooltip tooltip-left"
                        data-tip="Sort by comment date or in the order they appear in the video"
                    >
                        <Dropdown
                            trigger={
                                <div className="flex items-center gap-2 text-gray-600 hover:text-gray-900">
                                    <BiSort className="text-lg" />
                                    <span className="text-sm">
                                        {sortOption === "latest"
                                            ? "Latest Comments"
                                            : "Video Order"}
                                    </span>
                                </div>
                            }
                            items={sortingOptions}
                            align="right"
                            triggerClassName="px-3 py-2 rounded-md hover:bg-gray-100"
                            menuClassName="w-40"
                        />
                    </div>
                </div>
            </div>

            <div className="flex-1 overflow-y-auto bg-gray-50 p-4 space-y-4">
                {isLoading ? (
                    <div className="flex justify-center items-center h-32">
                        <div className="w-8 h-8 border-4 border-t-purple-600 border-purple-200 rounded-full animate-spin" />
                    </div>
                ) : comments.length > 0 ? (
                    comments.map(comment => (
                        <div
                            key={comment.id}
                            className="bg-white rounded-lg shadow-sm border border-gray-200 hover:border-purple-200 transition-colors duration-200"
                        >
                            <CommentItem
                                comment={comment}
                                onSeek={onSeek}
                                onCommentUpdate={onCommentAdded}
                                videoId={videoId}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                currentTime={currentTime}
                                currentUserEmail={currentUserEmail}
                                fetchReplies={fetchReplies}
                                depth={0}
                                isLoadingReplies={loadingReplies[comment.id]}
                                readOnly={readOnly}
                                shareToken={shareToken}
                            />
                        </div>
                    ))
                ) : (
                    <div className="flex flex-col items-center justify-center h-full text-gray-500 space-y-2">
                        <BiSolidMessageSquareDetail size={80} />
                        <p className="text-sm font-medium">
                            No comments yet. {!readOnly && "Be the first to comment!"}
                        </p>
                    </div>
                )}
            </div>

            <div className="bg-white rounded-b-lg shadow-sm border-t border-gray-200">
                {readOnly ? (
                    <div className="flex justify-center">
                        <p>You don't have permission to comment</p>
                    </div>
                ) : (
                    <AddComment
                        videoId={videoId}
                        currentTime={currentTime}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                        onCommentAdded={onCommentAdded}
                        shareToken={shareToken}
                    />
                )}
            </div>
        </div>
    );
};

export default CommentsSection;
