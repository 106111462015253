import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../assets/logo/icon.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";

interface SignUpFormData {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    company: string;
    usage: string;
}

const SignUp: React.FC = () => {
    const [formData, setFormData] = useState<SignUpFormData>({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        company: "",
        usage: "",
    });
    const [errors, setErrors] = useState<Partial<SignUpFormData>>({});
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors: Partial<SignUpFormData> = {};

        if (formData.first_name.trim() === "") {
            newErrors.first_name = "First Name is required";
        }
        if (formData.last_name.trim() === "") {
            newErrors.last_name = "Last Name is required";
        }
        if (formData.email.trim() === "") {
            newErrors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = "Invalid email address";
        }
        if (formData.password.trim() === "") {
            newErrors.password = "Password is required";
        } else if (formData.password.length < 8) {
            newErrors.password = "Password must be at least 8 characters long";
        }
        if (formData.company.trim() === "") {
            newErrors.company = "Company is required";
        }
        if (formData.usage.trim() === "") {
            newErrors.usage = "Usage is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSignUp = async () => {
        if (validateForm()) {
            setIsLoading(true);

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/signup`,
                    formData
                );

                if (response.status === 200 || response.status === 300) {
                    const { email, first_name, last_name } = formData;
                    localStorage.setItem("email", email);
                    localStorage.setItem("first_name", first_name);
                    localStorage.setItem("last_name", last_name);
                    navigate("/verification");
                } else {
                    const errorData = await response.data.error;
                    alert(errorData);
                }
            } catch (error: any) {
                if (error.response.status === 300) {
                    const { email, first_name, last_name } = formData;
                    localStorage.setItem("email", email);
                    localStorage.setItem("first_name", first_name);
                    localStorage.setItem("last_name", last_name);
                    navigate("/verification");
                } else {
                    console.error("Error during sign-up:", error);
                    alert("An error occurred during sign-up. Please try again.");
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                <div className="mb-6">
                    <div className="flex justify-center">
                        <img src={icon} alt="logo" className="h-12 md:h-16 mb-2" />
                    </div>
                    <p className="text-2xl mb-5 font-bold">Welcome to QuickSight</p>
                </div>

                <div className="flex justify-between mb-4">
                    <div className="w-[48%]">
                        <label htmlFor="firstName" className="flex pl-1 pb-1">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="first_name"
                            placeholder="First Name"
                            className={`input input-bordered focus:outline-none w-full ${
                                errors.first_name ? "border-red-500" : ""
                            }`}
                            onChange={handleInputChange}
                        />
                        {errors.first_name && (
                            <p className="text-red-500 text-xs italic text-left mt-1">
                                {errors.first_name}
                            </p>
                        )}
                    </div>
                    <div className="w-[48%]">
                        <label htmlFor="lastName" className="flex pl-1 pb-1">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="last_name"
                            placeholder="Last Name"
                            className={`input input-bordered focus:outline-none w-full ${
                                errors.last_name ? "border-red-500" : ""
                            }`}
                            onChange={handleInputChange}
                        />
                        {errors.last_name && (
                            <p className="text-red-500 text-xs italic text-left mt-1">
                                {errors.last_name}
                            </p>
                        )}
                    </div>
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="flex pl-1 pb-1">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required
                        className={`input input-bordered focus:outline-none w-full ${
                            errors.email ? "border-red-500" : ""
                        }`}
                        onChange={handleInputChange}
                    />
                    {errors.email && (
                        <p className="text-red-500 text-xs italic text-left mt-1">{errors.email}</p>
                    )}
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="flex pl-1 pb-1">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            required
                            className={`input input-bordered focus:outline-none w-full pr-10 ${
                                errors.password ? "border-red-500" : ""
                            }`}
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 mr-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaRegEyeSlash /> : <MdOutlineRemoveRedEye />}
                        </button>
                    </div>
                    {errors.password && (
                        <p className="text-red-500 text-xs italic text-left mt-1">
                            {errors.password}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label htmlFor="company" className="flex pl-1 pb-1">
                        Company
                    </label>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Company Name"
                        required
                        className={`input input-bordered focus:outline-none w-full ${
                            errors.company ? "border-red-500" : ""
                        }`}
                        onChange={handleInputChange}
                    />
                    {errors.company && (
                        <p className="text-red-500 text-xs italic text-left mt-1">
                            {errors.company}
                        </p>
                    )}
                </div>
                <div className="mb-6">
                    <label htmlFor="usage" className="flex pl-1 pb-1">
                        How do you see yourself using our platform?
                    </label>
                    <input
                        type="text"
                        id="usage"
                        name="usage"
                        placeholder="How do you see yourself using our platform?"
                        required
                        className={`input input-bordered focus:outline-none w-full ${
                            errors.usage ? "border-red-500" : ""
                        }`}
                        onChange={handleInputChange}
                    />
                    {errors.usage && (
                        <p className="text-red-500 text-xs italic text-left mt-1">{errors.usage}</p>
                    )}
                </div>
                <button
                    type="button"
                    className={`btn btn-primary w-full text-white`}
                    onClick={handleSignUp}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className="loading loading-spinner loading-xs">Loading...</span>
                    ) : (
                        "Sign Up"
                    )}
                </button>
                <p className="mt-4">
                    Already have an account?{" "}
                    <a href="/login" className="text-blue-500 hover:underline">
                        Sign in
                    </a>
                </p>
            </div>
        </div>
    );
};

export default SignUp;
