import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import icon from "../assets/logo/icon.png";

interface ErrorResponse {
    detail: string;
}

const Verification: React.FC = () => {
    const [email, setEmail] = useState<string | null>(null);
    const [code, setCode] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resendTimer, setResendTimer] = useState<number>(15);
    const [showResendButton, setShowResendButton] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        setEmail(storedEmail);

        const timer = setInterval(() => {
            setResendTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (resendTimer === 0) {
            setShowResendButton(true);
        }
    }, [resendTimer]);

    const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
        setError(null); // Clear the error when the user starts typing
    };

    const handleVerification = async () => {
        if (code.trim() === "") {
            setError("Please enter the verification code.");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/verify_code`, {
                email: email,
                code: code,
            });

            if (response.status === 200) {
                const { access, refresh, user } = response.data;
                localStorage.setItem("token", access);
                localStorage.setItem("refresh", refresh);
                localStorage.setItem("first_name", user.first_name);
                localStorage.setItem("last_name", user.last_name);
                localStorage.setItem("email", user.email);
                localStorage.setItem("user_id", user.id);
                window.location.href = "/home";
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError<ErrorResponse>;
                if (axiosError.response) {
                    setError(axiosError.response.data.detail);
                } else {
                    setError("An error occurred during verification. Please try again.");
                }
            } else {
                console.error("Error during verification:", error);
                setError("An error occurred during verification. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendNotification = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/resend_verification_code`,
                {
                    email,
                }
            );

            if (response.status === 200) {
                const { access, refresh, user } = response.data;
                localStorage.setItem("token", access);
                localStorage.setItem("refresh", refresh);
                localStorage.setItem("first_name", user.first_name);
                localStorage.setItem("last_name", user.last_name);
                localStorage.setItem("email", user.email);
                localStorage.setItem("user_id", user.id);
                navigate("/home");
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError<ErrorResponse>;
                if (axiosError.response) {
                    // @ts-ignore
                    setError(axiosError.response.error);
                } else {
                    setError("An error occurred during verification. Please try again.");
                }
            } else {
                console.error("Error during verification:", error);
                setError("An error occurred during verification. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center">
            <div className="flex flex-col text-center w-[400px] rounded-lg outline outline-1 outline-gray-300 p-12">
                <div className="flex justify-center">
                    <img src={icon} alt="logo" className="h-12 md:h-20 mb-4" />
                </div>
                <p className="text-xl font-bold mb-10">
                    We've sent a verification code to your email
                </p>
                <input
                    required
                    type="text"
                    placeholder="Verification Code"
                    className={`input input-bordered focus:outline-none p-5 mb-4 ${error ? "border-red-500" : ""}`}
                    value={code}
                    onChange={handleCodeChange}
                />
                {error && <p className="text-red-500 mb-4 ml-0">{error}</p>}
                <button
                    className={"btn btn-primary mt-4 text-white"}
                    onClick={handleVerification}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className="loading loading-spinner loading-xs">Loading...</span>
                    ) : (
                        "Verify"
                    )}
                </button>
                {!showResendButton && (
                    <p className="mt-4">
                        Didn't receive notification? Resend Notification in {resendTimer} seconds.
                    </p>
                )}
                {showResendButton && (
                    <p className="mt-4">
                        Didn't receive notification?
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={handleResendNotification}
                        >
                            {" "}
                            Resend{" "}
                        </span>
                        Notification
                    </p>
                )}
            </div>
        </div>
    );
};

export default Verification;
