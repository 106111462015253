import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../assets/logo/icon.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";

interface ResetPasswordFormData {
    email: string;
    code: string;
    new_password: string;
}

const ResetPassword: React.FC = () => {
    const [formData, setFormData] = useState<ResetPasswordFormData>({
        email: "",
        code: "",
        new_password: "",
    });
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const email = localStorage.getItem("reset_email");
        if (email) {
            setFormData(prev => ({ ...prev, email }));
        } else {
            navigate("/forgot-password");
        }
    }, [navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setError("");
    };

    const validateForm = () => {
        if (!formData.code) {
            setError("Verification code is required");
            return false;
        }
        if (!formData.new_password) {
            setError("New password is required");
            return false;
        }
        if (formData.new_password.length < 8) {
            setError("Password must be at least 8 characters long");
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API}/api/reset-password`,
                    formData
                );

                if (response.status === 200) {
                    setSuccess(true);
                    localStorage.removeItem("reset_email");
                    setTimeout(() => {
                        navigate("/login");
                    }, 3000);
                }
            } catch (error: any) {
                setError(error.response?.data?.message || "An error occurred. Please try again.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    if (success) {
        return (
            <div className="flex justify-center mt-[100px]">
                <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                    <div className="mb-6">
                        <div className="flex justify-center">
                            <img src={icon} alt="logo" className="h-12 md:h-16 mb-2" />
                        </div>
                        <p className="text-2xl mb-5 font-bold">Password Reset Successful</p>
                    </div>
                    <p className="mb-6">
                        Your password has been reset successfully. You can now log in with your new
                        password.
                    </p>
                    <p className="text-sm text-gray-600 mb-6">
                        Redirecting you to the login page...
                    </p>
                    <button
                        type="button"
                        className="btn btn-primary w-full text-white"
                        onClick={() => navigate("/login")}
                    >
                        Continue to Login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex justify-center mt-[100px]">
            <div className="flex flex-col text-center w-[500px] rounded-lg outline outline-1 outline-gray-300 p-8">
                <div className="mb-6">
                    <div className="flex justify-center">
                        <img src={icon} alt="logo" className="h-12 md:h-16 mb-2" />
                    </div>
                    <p className="text-2xl mb-5 font-bold">Reset Your Password</p>
                    <p className="text-gray-600">
                        Enter the verification code sent to your email and your new password.
                    </p>
                </div>

                <div className="mb-4">
                    <label htmlFor="code" className="flex pl-1 pb-1">
                        Verification Code
                    </label>
                    <input
                        type="text"
                        id="code"
                        name="code"
                        placeholder="Enter verification code"
                        required
                        className="input input-bordered focus:outline-none w-full"
                        onChange={handleInputChange}
                        value={formData.code}
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="new_password" className="flex pl-1 pb-1">
                        New Password
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="new_password"
                            name="new_password"
                            placeholder="Enter new password"
                            required
                            className="input input-bordered focus:outline-none w-full pr-10"
                            onChange={handleInputChange}
                            value={formData.new_password}
                        />
                        <button
                            type="button"
                            className="absolute inset-y-0 right-0 mr-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaRegEyeSlash /> : <MdOutlineRemoveRedEye />}
                        </button>
                    </div>
                </div>

                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

                <button
                    type="button"
                    className="btn btn-primary w-full text-white"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <span className="loading loading-spinner loading-xs">Loading...</span>
                    ) : (
                        "Reset Password"
                    )}
                </button>

                <p className="mt-4">
                    Didn't receive the code?{" "}
                    <a href="/forgot-password" className="text-blue-500 hover:underline">
                        Request again
                    </a>
                </p>
            </div>
        </div>
    );
};

export default ResetPassword;
