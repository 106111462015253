import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import QuickReview from "./QuickReview";

interface ShareValidation {
    video_id: string;
    permission: "viewer" | "commenter";
    expires_at: string | null;
}

const SharedVideo: React.FC = () => {
    const { shareToken } = useParams<{ shareToken: string }>();
    const [validation, setValidation] = useState<ShareValidation | null>(null);

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API}/api/sharing_router/validate-share-token/${shareToken}`
                );
                setValidation(response.data);
            } catch (error) {
                // Handle error
            }
        };

        if (shareToken) validateToken();
    }, [shareToken]);

    if (!validation) return null;

    return (
        <QuickReview
            videoId={validation.video_id}
            isSharedAccess={true}
            permission={validation.permission}
            shareToken={shareToken}
        />
    );
};

export default SharedVideo;
