import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { AiOutlineClockCircle, AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import { formatTime } from "./utils";
import { Comment } from "./types";

interface AddCommentProps {
    videoId: string;
    currentTime: number;
    isPlaying: boolean;
    setIsPlaying: (playing: boolean) => void;
    onCommentAdded: () => void;
    parentComment?: Comment | null;
    onCancel?: () => void;
    initialContent?: string;
    commentId?: string;
    isEditing?: boolean;
    shareToken?: string;
}

export const AddComment: React.FC<AddCommentProps> = ({
    videoId,
    currentTime,
    isPlaying,
    setIsPlaying,
    onCommentAdded,
    parentComment = null,
    onCancel,
    initialContent = "",
    commentId,
    isEditing = false,
    shareToken,
}) => {
    const [content, setContent] = useState(initialContent);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [endTime, setEndTime] = useState<number | null>(null);
    const [isCapturingTime, setIsCapturingTime] = useState<boolean>(false);
    const wasEmptyRef = useRef<boolean>(!initialContent);

    const isReply = Boolean(parentComment);

    useEffect(() => {
        if (isEditing) {
            setStartTime(currentTime);
        }
    }, [isEditing, currentTime]);

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        const isEmpty = newValue.trim().length === 0;

        if (isEmpty) {
            wasEmptyRef.current = true;
        } else if (wasEmptyRef.current && !isEditing && !isReply) {
            setIsPlaying(false);
            setStartTime(currentTime);
            wasEmptyRef.current = false;
        }

        setContent(newValue);
    };

    const handleTimeCapture = (): void => {
        if (!isCapturingTime) {
            setStartTime(startTime ?? currentTime);
            setIsCapturingTime(true);
        } else {
            setIsPlaying(false);
            if (currentTime > (startTime ?? 0)) {
                setEndTime(currentTime);
            } else {
                alert("End time must be after start time");
            }
            setIsCapturingTime(false);
        }
    };

    const getHeaders = () => {
        if (shareToken) {
            return { "X-Share-Token": shareToken };
        }
        const token = localStorage.getItem("token");
        return { Authorization: `Bearer ${token}` };
    };

    const handleSubmit = async (): Promise<void> => {
        if (!content.trim()) {
            alert("Please enter a comment");
            return;
        }

        if (!isReply && !isEditing && !startTime) {
            alert("Please ensure you have a timestamp");
            return;
        }

        try {
            const endpoint = isEditing
                ? `${process.env.REACT_APP_API}/api/quick-review/update-comment/${videoId}/${commentId}`
                : `${process.env.REACT_APP_API}/api/quick-review/create-comment/${videoId}`;

            const method = isEditing ? "PUT" : "POST";

            const timeData =
                isReply && parentComment
                    ? {
                          start_time: parentComment.start_time,
                          end_time: parentComment.end_time,
                      }
                    : {
                          start_time: startTime!,
                          end_time: endTime || startTime!,
                      };

            const body = isEditing
                ? { content }
                : {
                      content,
                      ...timeData,
                      ...(isReply && parentComment && { parent_comment_id: parentComment.id }),
                  };

            await axios({
                method,
                url: endpoint,
                data: body,
                headers: getHeaders(),
            });

            setContent("");
            if (!isEditing && !isReply) {
                setStartTime(null);
                setEndTime(null);
                setIsCapturingTime(false);
                wasEmptyRef.current = true;
            }
            onCommentAdded();
            onCancel?.();
        } catch (error) {
            console.error("Error posting comment:", error);
            alert("Failed to post comment. Please try again.");
        }
    };

    return (
        <div className="bg-gray-100 p-4 rounded-md shadow-lg">
            {!isEditing && !isReply && (
                <div className="flex items-center gap-2 mb-3">
                    {startTime && (
                        <div className="flex items-center gap-2">
                            <span className="text-sm">
                                {formatTime(startTime)}
                                {endTime ? ` - ${formatTime(endTime)}` : ""}
                            </span>
                            <button
                                className="btn btn-ghost btn-sm text-error"
                                onClick={() => {
                                    setStartTime(null);
                                    setEndTime(null);
                                    setIsCapturingTime(false);
                                }}
                            >
                                <AiOutlineClose />
                            </button>
                        </div>
                    )}

                    {startTime && !endTime && (
                        <div
                            className="tooltip"
                            data-tip={isCapturingTime ? "Click to set end time" : "Add end time"}
                        >
                            <button
                                className={`btn btn-sm ${isCapturingTime ? "btn-primary" : "btn-outline"}`}
                                onClick={handleTimeCapture}
                            >
                                <AiOutlineClockCircle className="text-lg mr-1" />
                                {isCapturingTime ? "Capture End" : "Add End Time"}
                            </button>
                        </div>
                    )}
                </div>
            )}

            <div className="space-y-2">
                <textarea
                    placeholder={isReply ? "Write a reply..." : "Start typing to add a comment..."}
                    value={content}
                    onChange={handleCommentChange}
                    onKeyDown={e => {
                        if (e.code === "Space" && document.activeElement === e.target) {
                            e.stopPropagation();
                        }
                    }}
                    rows={3}
                    className="textarea textarea-bordered w-full focus:outline-none"
                />

                <div className="flex gap-2">
                    {onCancel && (
                        <button className="btn btn-ghost flex-1" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                    <button
                        className={`btn flex-1 text-white ${
                            (!startTime && !isEditing && !isReply) || !content.trim()
                                ? "btn-disabled"
                                : "btn-primary"
                        }`}
                        onClick={handleSubmit}
                        disabled={(!startTime && !isEditing && !isReply) || !content.trim()}
                    >
                        <AiOutlineSend className="mr-2 text-white" />
                        {isEditing ? "Save" : isReply ? "Reply" : "Comment"}
                    </button>
                </div>
            </div>
        </div>
    );
};
