import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { FiShare2 } from "react-icons/fi";
import { Video } from "../components/VideoTile";
import CommentsSection from "../components/QuickReview/CommentsSection";
import Transcript, { Word, fetchSubtitles } from "../components/QuickReview/Transcript";
import ResizablePanels from "../components/ResizeablePanel";
import { Comment, CommentResponse } from "../components/QuickReview/types";
import ShareDialog from "../components/QuickReview/ShareDialog";

interface QuickReviewProps {
    videoId?: string;
    isSharedAccess?: boolean;
    permission?: "viewer" | "commenter";
    shareToken?: string;
}

const QuickReview: React.FC<QuickReviewProps> = ({
    videoId: propVideoId,
    isSharedAccess = false,
    permission = "viewer",
    shareToken,
}) => {
    const navigate = useNavigate();
    const { videoId: paramVideoId } = useParams<{ videoId: string }>();
    const actualVideoId = propVideoId || paramVideoId;

    const [video, setVideo] = useState<Video | null>(null);
    const [comments, setComments] = useState<Comment[]>([]);
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
    const [words, setWords] = useState<Word[]>([]);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [streamUrl, setStreamUrl] = useState<string>("");
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [currentUserEmail, setCurrentUserEmail] = useState<string>("");
    const [showTranscript, setShowTranscript] = useState<boolean>(true);
    const [showComments, setShowComments] = useState<boolean>(true);
    const [canComment, setCanComment] = useState<boolean>(true);
    const [panelSizes, setPanelSizes] = useState<number[]>([0.2, 0.5, 0.3]);
    const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const playerRef = useRef<ReactPlayer>(null);

    useEffect(() => {
        const savedSizes = localStorage.getItem("quick-review-panel-sizes");
        if (savedSizes) {
            setPanelSizes(JSON.parse(savedSizes));
        }
    }, []);

    useEffect(() => {
        if (isSharedAccess) {
            setCanComment(permission === "commenter");
        }
    }, [isSharedAccess, permission]);

    const updatePanelSizes = useCallback((showingTranscript: boolean, showingComments: boolean) => {
        if (!showingTranscript && !showingComments) {
            setPanelSizes([1]);
        } else if (!showingTranscript && showingComments) {
            setPanelSizes([0.6, 0.4]);
        } else if (showingTranscript && !showingComments) {
            setPanelSizes([0.4, 0.6]);
        } else {
            setPanelSizes([0.3, 0.4, 0.3]);
        }
    }, []);

    const handleTranscriptToggle = useCallback(() => {
        const currentVideoTime = playerRef.current?.getCurrentTime() || 0;
        setShowTranscript(prev => {
            const newShowTranscript = !prev;
            updatePanelSizes(newShowTranscript, showComments);
            setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.seekTo(currentVideoTime, "seconds");
                }
            }, 0);
            return newShowTranscript;
        });
    }, [showComments, updatePanelSizes]);

    const handleCommentsToggle = useCallback(() => {
        const currentVideoTime = playerRef.current?.getCurrentTime() || 0;
        setShowComments(prev => {
            const newShowComments = !prev;
            updatePanelSizes(showTranscript, newShowComments);
            setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.seekTo(currentVideoTime, "seconds");
                }
            }, 0);
            return newShowComments;
        });
    }, [showTranscript, updatePanelSizes]);

    const fetchComments = async (): Promise<void> => {
        try {
            setIsLoadingComments(true);
            const headers: Record<string, string> = {};

            if (isSharedAccess && shareToken) {
                headers["X-Share-Token"] = shareToken;
            } else {
                const token = localStorage.getItem("token");
                if (token) headers["Authorization"] = `Bearer ${token}`;
            }

            const response = await axios.get<CommentResponse>(
                `${process.env.REACT_APP_API}/api/quick-review/comments/${actualVideoId}`,
                { headers, params: { page: 1, page_size: 50 } }
            );
            setComments(response.data.items);
        } catch (error) {
            console.error("Error fetching comments:", error);
        } finally {
            setIsLoadingComments(false);
        }
    };

    const handleProgress = useCallback(({ playedSeconds }: { playedSeconds: number }) => {
        setCurrentTime(playedSeconds);
    }, []);

    useEffect(() => {
        const init = async () => {
            try {
                setIsLoading(true);
                setError(null);

                if (isSharedAccess) {
                    if (!shareToken) {
                        throw new Error("Invalid share token");
                    }

                    const response = await axios.get(
                        `${process.env.REACT_APP_API}/api/sharing_router/get_shared_video_details/${actualVideoId}`,
                        {
                            headers: {
                                "X-Share-Token": shareToken,
                            },
                        }
                    );

                    setVideo(response.data);
                    setStreamUrl(response.data.stream_url);
                    setCanComment(response.data.shared_permission === "commenter");

                    if (response.data.subtitle_word || response.data.subtitle_url) {
                        const subtitleUrl =
                            response.data.subtitle_word || response.data.subtitle_url;
                        const parsedWords = await fetchSubtitles(subtitleUrl);
                        setWords(parsedWords);
                    }
                } else {
                    const token = localStorage.getItem("token");
                    if (!token) {
                        navigate("/login");
                        return;
                    }

                    const headers = { Authorization: `Bearer ${token}` };

                    const [userResponse, videoResponse] = await Promise.all([
                        axios.get(`${process.env.REACT_APP_API}/api/get_user_details`, { headers }),
                        axios.get(
                            `${process.env.REACT_APP_API}/api/get_video_details/${actualVideoId}`,
                            { headers }
                        ),
                    ]);

                    setCurrentUserEmail(userResponse.data.email);
                    setVideo(videoResponse.data);
                    setStreamUrl(videoResponse.data.stream_url);

                    if (videoResponse.data.subtitle_word || videoResponse.data.subtitle_url) {
                        const subtitleUrl =
                            videoResponse.data.subtitle_word || videoResponse.data.subtitle_url;
                        const parsedWords = await fetchSubtitles(subtitleUrl);
                        setWords(parsedWords);
                    }
                }

                await fetchComments();
            } catch (err: any) {
                console.error("Error initializing QuickReview:", err);
                setError(err.response?.data?.detail || "An error occurred loading the video");
            } finally {
                setIsLoading(false);
            }
        };

        init();

        const handleKeyPress = (event: KeyboardEvent) => {
            if (
                event.code === "Space" &&
                !(document.activeElement instanceof HTMLTextAreaElement)
            ) {
                event.preventDefault();
                setIsPlaying(prev => !prev);
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => window.removeEventListener("keydown", handleKeyPress);
    }, [actualVideoId, isSharedAccess, shareToken, permission, navigate]);

    const transcriptPanel = useMemo(
        () => (
            <div className="h-full px-4">
                <Transcript
                    words={words}
                    currentTime={currentTime}
                    onWordClick={time => playerRef.current?.seekTo(time, "seconds")}
                />
            </div>
        ),
        [words, currentTime]
    );

    const videoPanel = useMemo(
        () => (
            <div className="h-full px-4">
                <div className="bg-black h-full">
                    <ReactPlayer
                        ref={playerRef}
                        url={streamUrl}
                        controls
                        width="100%"
                        height="100%"
                        playing={isPlaying}
                        onProgress={handleProgress}
                        progressInterval={100}
                    />
                </div>
            </div>
        ),
        [streamUrl, isPlaying, handleProgress]
    );

    const commentsPanel = useMemo(
        () => (
            <div className="h-full px-4">
                <CommentsSection
                    comments={comments}
                    onSeek={time => playerRef.current?.seekTo(time)}
                    videoId={actualVideoId!}
                    currentTime={currentTime}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    onCommentAdded={fetchComments}
                    currentUserEmail={currentUserEmail}
                    isLoading={isLoadingComments}
                    readOnly={!canComment}
                    shareToken={shareToken}
                />
            </div>
        ),
        [
            comments,
            currentTime,
            isPlaying,
            actualVideoId,
            currentUserEmail,
            isLoadingComments,
            canComment,
        ]
    );

    const getVisiblePanels = () => {
        const panels = [];
        if (showTranscript) panels.push(transcriptPanel);
        panels.push(videoPanel);
        if (showComments) panels.push(commentsPanel);
        return panels;
    };

    const handlePanelResize = (newSizes: number[]) => {
        setPanelSizes(newSizes);
        localStorage.setItem("quick-review-panel-sizes", JSON.stringify(newSizes));
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-96">
                <span className="loading loading-spinner loading-lg text-primary"></span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-96 gap-4">
                <div className="text-xl text-red-500">Error loading video</div>
                <div className="text-gray-600">{error}</div>
                <button onClick={() => navigate("/")} className="btn btn-outline">
                    Return Home
                </button>
            </div>
        );
    }

    if (!video) {
        return null;
    }

    return (
        <div className="h-[calc(100vh-theme(space.40))] px-4 py-4">
            <div className="flex gap-2 mb-4">
                <button
                    onClick={handleTranscriptToggle}
                    className="btn btn-sm btn-outline flex items-center gap-1"
                >
                    {showTranscript ? (
                        <>
                            <MdChevronLeft size={16} /> Hide Transcript
                        </>
                    ) : (
                        <>
                            Show Transcript <MdChevronRight size={16} />
                        </>
                    )}
                </button>
                <button
                    onClick={handleCommentsToggle}
                    className="btn btn-sm btn-outline flex items-center gap-1"
                >
                    {showComments ? (
                        <>
                            Hide Comments <MdChevronRight size={16} />
                        </>
                    ) : (
                        <>
                            <MdChevronLeft size={16} /> Show Comments
                        </>
                    )}
                </button>

                {!isSharedAccess && (
                    <button
                        onClick={() => setIsShareModalOpen(true)}
                        className="btn btn-sm btn-outline flex items-center gap-1"
                    >
                        <FiShare2 className="w-4 h-4" />
                        Share
                    </button>
                )}
            </div>

            <ResizablePanels
                defaultSizes={panelSizes}
                minSize={0.2}
                onResize={handlePanelResize}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
            >
                {getVisiblePanels()}
            </ResizablePanels>

            <ShareDialog
                videoId={actualVideoId!}
                isOpen={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
            />
        </div>
    );
};

export default QuickReview;
