import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoCreditCard } from "react-icons/go";
import Companies from "../components/Welcome/Companies";
import FeatureContainer from "../components/Welcome/FeatureContainer";
// import working from "../assets/working.jpg";
import QuickSightBeta from "../assets/logo/QuickSight.png";
import working from "../assets/Group 13.png";
import searchImg from "../assets/features/search.jpeg";
import analyzeImg from "../assets/features/analyze.jpeg";
import apiImg from "../assets/features/api.jpeg";
import welcomebg from "../assets/welcomebg.png";
import purple from "../assets/purple.jpg";
import logEvent from "../amplitude";

import Testimonials from "../components/Welcome/Testimonials";

const Welcome: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Welcome",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <div className="flex flex-col">
            <HeroSection navigate={navigate} />
            <FeaturesSection navigate={navigate} />
            <HowItWorksSection navigate={navigate} />
            <UseCasesSection />
            <ProblemSpaceSection />
            {/* <TestimonialsSection /> */}
            <FAQSection navigate={navigate} />
            <CTASection navigate={navigate} />
            <Footer />
        </div>
    );
};

const HeroSection: React.FC<{ navigate: Function }> = ({ navigate }) => {
    const handleGetStartedClick = () => {
        logEvent("button_click", {
            button_name: "Get Started",
            button_location: "Hero Section",
        });
        navigate("/signup");
    };

    const handleLearnMoreClick = () => {
        logEvent("button_click", {
            button_name: "Learn More",
            button_location: "Hero Section",
        });

        window.location.href = "https://calendly.com/abhinav-vw1y/30min";
    };
    return (
        <div
            style={{ backgroundImage: `url(${welcomebg})` }}
            className="min-h-screen pt-8 pb-10 md:pt-32 md:pb-20 mt-[-2.5%] bg-cover bg-center bg-no-repeat"
        >
            <div className="container mx-auto px-4">
                <div className="hero-content flex-col lg:flex-row-reverse justify-between gap-10 w-full">
                    <div className="video-container lg:ml-10 aspect-w-16 aspect-h-9">
                        <iframe
                            src="https://www.youtube.com/embed/xswT4PaOwA4?autoplay=1&mute=1"
                            title="QuickSight Demo"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="w-[90vw] h-[30vh] lg:w-[650px] lg:h-[400px]"
                        ></iframe>
                    </div>
                    <div>
                        <h1 className="text-4xl md:text-6xl font-bold mb-6">
                            Unlock the Power of Your Videos with AI-Driven Search
                        </h1>
                        <p className="text-lg md:text-xl mb-8">
                            Instantly find actions, objects, text, and conversations in your videos
                            using natural language queries - revolutionize how you interact with
                            your video content.
                        </p>
                        <div className="flex flex-col md:flex-row gap-4">
                            <button
                                className="btn btn-primary text-white"
                                onClick={handleGetStartedClick}
                            >
                                Get Started For Free
                            </button>
                            <button className="btn btn-outline" onClick={handleLearnMoreClick}>
                                Book Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-10 md:mt-32">
                <Companies />
            </div>
        </div>
    );
};

const FeaturesSection: React.FC<{ navigate: Function }> = ({ navigate }) => {
    const handleTryNowClick = () => {
        logEvent("button_click", {
            button_name: "Try QuickSight Now",
            button_location: "Features Section",
        });
        navigate("/signup");
    };

    return (
        <div className="py-20 px-6 md:px-24 bg-[#f9faf9]">
            <div className="text-center mb-10">
                <p className="text-lg font-medium text-[#862FE7] mb-2">FEATURES</p>
                <p className="text-3xl md:text-4xl font-bold">
                    Unleash the Full Potential of Your Video Library
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                <FeatureContainer
                    img={analyzeImg}
                    title="Comprehensive Analysis"
                    description="Advanced AI Analysis
                    Extract deep insights from your videos with cutting-edge artificial intelligence."
                />
                <FeatureContainer
                    img={searchImg}
                    title="Intuitive Search"
                    description="Natural Language Search
                    Find exactly what you need using simple, conversational queries."
                />
                <FeatureContainer
                    img={apiImg}
                    title="Seamless Integration"
                    description="Developer-Friendly Integration
                    Enhance your applications with our powerful, easy-to-use APIs."
                />
            </div>
            <div className="mt-10 text-center">
                <button className="btn btn-primary text-white" onClick={handleTryNowClick}>
                    Try QuickSight Now
                </button>
            </div>
        </div>
    );
};

const HowItWorksSection: React.FC<{ navigate: Function }> = ({ navigate }) => {
    const handleTryNowClick = () => {
        logEvent("button_click", {
            button_name: "Try QuickSight Now",
            button_location: "How It Works Section",
        });
        navigate("/signup");
    };
    return (
        <div className="py-20 px-6 md:px-24" style={{ backgroundImage: `url(${welcomebg})` }}>
            <div className="text-center mb-10">
                <p className="text-lg font-medium text-[#862FE7] mb-2">HOW IT WORKS</p>
                <p className="text-3xl md:text-4xl font-bold">Video Intelligence Made Simple</p>
            </div>
            <div className="flex justify-center">
                <img src={working} alt="How it works" className="max-w-full" />
            </div>
            <div className="mt-10 text-center">
                <button className="btn btn-primary text-white" onClick={handleTryNowClick}>
                    Try QuickSight Now
                </button>
            </div>
        </div>
    );
};

const UseCasesSection: React.FC = () => (
    <div className="py-20 px-6 md:px-24">
        <div className="text-center mb-10">
            <p className="text-lg font-medium text-[#862FE7] mb-2">USE CASES</p>
            <p className="text-3xl md:text-4xl font-bold mb-4">
                Transform Your Industry with Video Intelligence
            </p>
            <p className="text-lg">
                Discover how QuickSight empowers businesses across various sectors
            </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <UseCaseCard
                title="E-learning"
                description="Enhance online learning experiences by making educational videos searchable and easily accessible. QuickSight enables students to find relevant content quickly, improving engagement and retention."
                borderColor="border-indigo-500"
            />
            <UseCaseCard
                title="Media & Entertainment"
                description="Streamline media asset management and content discovery for studios and streaming platforms. QuickSight's intelligent video analysis and search capabilities help you optimize content organization and improve viewer experiences."
                borderColor="border-green-500"
            />
            <UseCaseCard
                title="Enterprise Knowledge Management"
                description="Empower employees to quickly find relevant information in corporate training and knowledge base videos. QuickSight's semantic search and intelligent indexing make it easy to access the insights hidden within your video content."
                borderColor="border-yellow-500"
            />
            <UseCaseCard
                title="Security & Surveillance"
                description="Enhance public safety by quickly identifying persons of interest or suspicious activities in surveillance footage. QuickSight's advanced video analysis capabilities enable security professionals to respond to threats more efficiently."
                borderColor="border-red-500"
            />
            <UseCaseCard
                title="Healthcare"
                description="Improve patient care by efficiently accessing relevant information in medical training and patient education videos. QuickSight helps healthcare professionals find the knowledge they need to make informed decisions and deliver better outcomes."
                borderColor="border-purple-500"
            />
            <UseCaseCard
                title="Retail & E-commerce"
                description="Enhance product discoverability and customer engagement through video search and recommendations. QuickSight's AI-powered video understanding helps you deliver personalized experiences and drive sales."
                borderColor="border-pink-500"
            />
        </div>
    </div>
);

const UseCaseCard: React.FC<{
    title: string;
    description: string;
    borderColor: string;
}> = ({ title, description, borderColor }) => (
    <div className={`card bg-base-100 shadow-xl border-l-8 ${borderColor}`}>
        <div className="card-body">
            <h2 className="card-title">{title}</h2>
            <p>{description}</p>
            {/* <div className="card-actions justify-end">
                <button className="btn btn-primary text-white">Learn More</button>
            </div> */}
        </div>
    </div>
);

const FAQSection: React.FC<{ navigate: Function }> = ({ navigate }) => (
    <div className="py-20 px-6 md:px-24" style={{ backgroundImage: `url(${welcomebg})` }}>
        <div className="text-center mb-10">
            <p className="text-lg font-medium text-[#862FE7] mb-2">FAQ</p>
            <p className="text-3xl md:text-4xl font-bold mb-4">Get Answers to Your Questions</p>
            <p className="text-lg">
                Have more questions? Reach out to our team – we're here to help!
            </p>
        </div>
        <div className="max-w-2xl mx-auto">
            <FAQItem
                question="What makes QuickSight unique?"
                answer="QuickSight is the only video intelligence platform that combines advanced AI video analysis with intuitive semantic search, enabling you to unlock valuable insights from your video content with unparalleled ease and precision."
            />
            <FAQItem
                question="How does QuickSight ensure data security and privacy?"
                answer="At QuickSight, we prioritize the security and privacy of your video data. We employ industry-leading security measures, including encryption, access controls, and regular audits, to ensure that your content remains safe and confidential."
            />
            <FAQItem
                question="Can I try QuickSight before committing to a paid plan?"
                answer="Absolutely! We offer a free trial that allows you to explore QuickSight's features and experience the value of video intelligence firsthand. Simply sign up on our website and start unlocking insights from your videos today."
            />
        </div>
        <div className="mt-10 text-center">
            <button className="btn btn-primary text-white" onClick={() => navigate("/signup")}>
                Sign Up Now
            </button>
        </div>
    </div>
);

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => (
    <div tabIndex={0} className="collapse collapse-arrow bg-white shadow mb-4">
        <div className="collapse-title text-xl font-medium">{question}</div>
        <div className="collapse-content">
            <p>{answer}</p>
        </div>
    </div>
);

const ProblemCard: React.FC<{
    title: string;
    description: string;
    icon: string;
}> = ({ title, description, icon }) => (
    <div className="card bg-white shadow-xl">
        <div className="card-body">
            <div className="text-4xl mb-4">{icon}</div>
            <h2 className="card-title">{title}</h2>
            <p>{description}</p>
        </div>
    </div>
);

const ProblemSpaceSection: React.FC = () => (
    <div className="py-20 px-6 md:px-24 bg-[#f9faf9]">
        <div className="text-center mb-10">
            <p className="text-lg font-medium text-[#862FE7] mb-2">PROBLEM SPACE</p>
            <p className="text-3xl md:text-4xl font-bold mb-4">
                Why Traditional Video Management Falls Short
            </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <ProblemCard
                title="Old-fashioned Video Tagging"
                description="Move on from outdated methods of manually tagging videos, which are inefficient and prone to errors."
                icon="🏷️"
            />
            <ProblemCard
                title="Time-consuming Manual Logging"
                description="Manually logging videos is not only time-consuming but also unscalable for large video libraries."
                icon="⏳"
            />
            <ProblemCard
                title="Incomplete Transcripts"
                description="Transcripts miss critical elements of your video, such as visuals and sounds, providing only partial context."
                icon="📝"
            />
            <ProblemCard
                title="Limited Object-level Tags"
                description="Object-level tags miss the broader context needed to add real value to your video content."
                icon="🏷️"
            />
        </div>
    </div>
);

const TestimonialsSection: React.FC = () => (
    <div className="py-20 px-6 md:px-24">
        <div className="text-center mb-10">
            <p className="text-lg font-medium text-[#862FE7] mb-2">TESTIMONIALS</p>
            <p className="text-3xl md:text-4xl font-bold mb-4">What Our Customers Are Saying</p>
            <p className="text-lg">
                We believe that video intelligence is the future. We are committed to delivering
                exceptional video intelligence services to our clients.
            </p>
        </div>
        <Testimonials />
    </div>
);

const CTASection: React.FC<{ navigate: Function }> = ({ navigate }) => {
    const handleSignUpClick = () => {
        logEvent("button_click", {
            button_name: "Sign Up For Free",
            button_location: "CTA Section",
        });
        navigate("/signup");
    };

    const handleRequestDemoClick = () => {
        logEvent("button_click", {
            button_name: "Request a Demo",
            button_location: "CTA Section",
        });
        window.location.href = "https://calendly.com/abhinav-vw1y";
    };

    return (
        <div
            className="py-20 px-6 m-5 md:m-24 rounded-2xl"
            style={{ backgroundImage: `url(${purple})` }}
        >
            <div className="text-center mb-10">
                <p className="text-lg text-white mb-2">GET STARTED WITH QUICKSIGHT</p>
                <p className="text-3xl md:text-4xl font-bold text-white mb-4">
                    Experience the Future of Video Intelligence
                </p>
                <p className="text-lg text-white">
                    Start your free trial today and unlock the hidden potential in your video
                    library
                </p>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center gap-6">
                <button className="btn btn-primary text-white" onClick={handleSignUpClick}>
                    Try For Free
                </button>
                <p className="text-white">or</p>
                <button
                    className="btn btn-outline text-white hover:bg-slate-400"
                    onClick={handleRequestDemoClick}
                >
                    Request a Demo
                </button>
            </div>
            <div className="mt-6 text-center flex justify-center items-center gap-2">
                <GoCreditCard className="text-xl text-white" />
                <p className="text-white">No credit card required</p>
            </div>
        </div>
    );
};

const Footer: React.FC = () => (
    // <footer className="footer footer-center p-6 bg-base-300 text-base-content">
    //     <div>
    //         <p className="mb-2">Copyright © 2024 - All right reserved by QuickSight, Inc</p>
    //         {/* <div className="flex flex-col md:flex-row gap-4 mb-4">
    //             <a href="/about">About Us</a>
    //             <a href="/privacy">Privacy Policy</a>
    //             <a href="/terms">Terms of Service</a>
    //         </div> */}
    //         <div className="flex gap-4">{/* Add social media icons */}</div>
    //     </div>
    // </footer>

    <div>
        <footer className="footer bg-base-200 text-base-content p-10 md:p-24">
            <aside>
                <a className="font-medium text-2xl hover:cursor-pointer">
                    <img src={QuickSightBeta} alt="logo" className="h-12" />
                </a>
            </aside>
            <nav>
                <h6 className="footer-title">Products</h6>
                <a className="link link-hover">Search</a>
                <a className="link link-hover">API docs</a>
                <a className="link link-hover">Pricing</a>
                <a className="link link-hover">Platform</a>
            </nav>
            <nav>
                <h6 className="footer-title">Company</h6>
                <a className="link link-hover">About us</a>
                <a className="link link-hover" href="/contact">Contact</a>
                <a className="link link-hover">Jobs</a>
                <a className="link link-hover">Press kit</a>
            </nav>
            <nav>
                <h6 className="footer-title">Legal</h6>
                <a className="link link-hover">Terms of use</a>
                <a className="link link-hover">Privacy policy</a>
                <a className="link link-hover">Cookie policy</a>
            </nav>
        </footer>
        <footer className="footer footer-center p-6 bg-base-300 text-base-content">
            <div>
                <p className="mb-2">Copyright © 2024 - All right reserved by QuickSight, Inc</p>
            </div>
        </footer>
    </div>
);

export default Welcome;
