import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import newLogo from "../../assets/logo/logo.svg";
import QuickSightBeta from "../../assets/logo/QuickSight.png";
import { AiOutlineLogout } from "react-icons/ai";
import NotificationDropdown from "../Notification";
import Modal from "../Modal";
import logEvent from "../../amplitude";

const NavBar = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [token, setToken] = React.useState<string | null>(localStorage.getItem("token"));
    const [showAPIModal, setShowAPIModal] = React.useState(false);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name");

    React.useEffect(() => {
        if (token) {
            setIsAuthenticated(true);
        }
    }, [token]);

    const handleSignOut = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        localStorage.removeItem("email");
        setIsAuthenticated(false);
        navigate("/login");
    };

    const showAPIRequestModal = () => {
        logEvent("button_click", {
            button_name: "API Request",
            button_location: "Navbar",
        });
        setShowAPIModal(!showAPIModal);
    };

    const closeDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.blur();
        }
    };

    const handleMenuClick = (action: () => void) => {
        closeDropdown();
        action();
    };

    return (
        <div className="navbar bg-base-100 shadow-md md:px-24 mb-10">
            <div className="navbar-start">
                <a className="font-medium text-2xl hover:cursor-pointer">
                    <img
                        src={QuickSightBeta}
                        alt="logo"
                        className="h-12 md:h-8"
                        onClick={() => navigate("/")}
                    />
                </a>
            </div>
            <div className="navbar-center hidden lg:flex">
                {isAuthenticated && (
                    <ul className="menu menu-horizontal px-1 gap-5 font-medium">
                        <li>
                            <a onClick={() => navigate("/home")}>Home</a>
                        </li>
                        <li>
                            <a onClick={() => navigate("/collections")}>Collections</a>
                        </li>
                        <li>
                            <a onClick={() => navigate("/about")}>About</a>
                        </li>
                    </ul>
                )}
            </div>
            <div className="navbar-end">
                {isAuthenticated ? (
                    <div className="flex items-center">
                        <div className="mr-5">
                            <button
                                className="btn btn-ghost text-red-400"
                                onClick={showAPIRequestModal}
                            >
                                Request API Access
                            </button>
                        </div>
                        <NotificationDropdown token={token} />
                        <div className="divider divider-horizontal"></div>
                        <div className="dropdown dropdown-end">
                            <div
                                tabIndex={0}
                                role="button"
                                className="btn btn-circle avatar placeholder"
                            >
                                <div className="bg-[#485464] text-white rounded-full w-16">
                                    <span className="text-lg">{firstName?.charAt(0)}</span>
                                </div>
                            </div>
                            <ul
                                ref={dropdownRef}
                                tabIndex={0}
                                className="mt-4 z-[11] p-3 outline outline-gray-200 menu menu-md dropdown-content bg-base-100 rounded-box w-52 gap-3"
                            >
                                <li className="truncate w-full">
                                    <p className="font-bold">
                                        {firstName} {lastName}
                                    </p>
                                </li>
                                <hr />
                                <li>
                                    <a onClick={() => handleMenuClick(() => navigate("/company"))}>
                                        Company
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() =>
                                            handleMenuClick(() => navigate("/change-password"))
                                        }
                                    >
                                        Change password
                                    </a>
                                </li>
                                <li>
                                    <div
                                        className="text-red-500 flex"
                                        onClick={() => handleMenuClick(handleSignOut)}
                                    >
                                        <AiOutlineLogout size={16} color="red" />
                                        <a>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center gap-5">
                        <a className="hidden md:btn" onClick={() => navigate("/login")}>
                            Sign in
                        </a>
                        <button
                            className="btn btn-primary text-white"
                            onClick={() => navigate("/signup")}
                        >
                            Get Started For Free
                        </button>
                    </div>
                )}
            </div>

            {showAPIModal && (
                <Modal
                    isOpen={showAPIModal}
                    onClose={showAPIRequestModal}
                    title="Request API Access"
                >
                    <p>
                        Please email <strong>abhinav@quicksight.ai</strong> with your use case for
                        early API access
                    </p>
                </Modal>
            )}
        </div>
    );
};

export default NavBar;
