import React from "react";

interface Props {
    img: string;
    title: string;
    description: string;
}

const FeatureContainer: React.FC<Props> = ({ img, title, description }) => {
    return (
        // <div className="card w-96 bg-base-100">
        //     <div className="card-body">
        //         <img src={img} className="max-h-16 m-4" />
        //         <h1 className="text-xl font-bold mb-2">{title}</h1>
        //         <p className="text-md font-normal">{description}</p>
        //     </div>
        // </div>

        <div className="card w-full h-94 bg-base-100 shadow-xl ">
            <img src={img} alt="feature-icon" className="object-cover m-5" />
            <div className="card-body items-center">
                <h2 className="card-title w-full">{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default FeatureContainer;
