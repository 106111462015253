import React from "react";
import accenture from "../../assets/company/accenture.png";
import flexport from "../../assets/company/flexport.png";
import smartbot360 from "../../assets/company/smartbot360.png";
import ucr from "../../assets/company/ucr.png";
import unqork from "../../assets/company/unqork.png";

const Companies: React.FC = () => {
    return (
        <div>
            <div className="flex flex-col justify-center items-center gap-8">
                <p className="text-md font-bold text-center">Built by a World Class Team From</p>

                <div className="flex items-center gap-10 md:gap-24">
                    <div className="flex flex-col gap-10 md:flex-row md:gap-24">
                        <img src={accenture} className="h-10" alt="Accenture" />
                        <img src={flexport} className="h-9 mt-2" alt="Flexport" />
                        {/* <img src={smartbot360} className="h-12 w-12" alt="Smartbot360" /> */}
                    </div>

                    <div className="flex flex-col gap-10 md:flex-row md:gap-24">
                        <img src={ucr} className="h-10" alt="UCR" />
                        <img src={unqork} className="h-8" alt="Unqork" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Companies;
